import React, { useState, useEffect } from "react";
import SearchBar from "./components/SearchBar";
import "./App.css";
import "./components/Table.css";
import { FaFilter } from "react-icons/fa"; // Import icon filter từ react-icons

function App() {
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]); // State để lưu nội dung bảng
  const [isLoading, setIsLoading] = useState(false);

  const [filterKho, setFilterKho] = useState([
    "BC2",
    "BC3",
    "TG1",
    "DT1",
    "VL1",
    "ST1",
    "BL1",
    "VTC",
  ]); // State để lưu các kho đã chọn
  const [filterLoai, setFilterLoai] = useState([
    "LOAI001",
    "LOAI002",
    "LOAI003",
  ]); // State để lưu các kho đã chọn

  const [modalFilterKho, setModalFilterKho] = useState([
    "BC2",
    "BC3",
    "TG1",
    "DT1",
    "VL1",
    "ST1",
    "BL1",
    "VTC",
  ]); // State để lưu các kho đã chọn
  const [modalFilterLoai, setModalFilterLoai] = useState([
    "LOAI001",
    "LOAI002",
    "LOAI003",
  ]); // State để lưu các kho đã chọn
  const [isModalOpen, setIsModalOpen] = useState(false); // State để quản lý trạng thái modal
  const [selectAll, setSelectAll] = useState(true); // State để quản lý chọn tất cả các checkbox
  const [selectAllLoai, setSelectAllLoai] = useState(true); // State để quản lý chọn tất cả các checkbox

  const handleSearch = async () => {
    try {
      setIsLoading(true); // Set loading state to true when API call is started

      // Tạo URL với query parameters từ state
      const body = {
        item_code: searchQuery,
        warehouse: filterKho,
        type_item: filterLoai,
      };

      // Gọi API với các tham số đã được thêm vào URL
      const response = await fetch(`https://cam4k.vn/data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Có lỗi xảy ra khi gọi API");
      }

      const data = await response.json();
      setTableData(data); // Cập nhật dữ liệu nhận được từ API
    } catch (error) {
      console.error("Lỗi:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when API call is finished
    }
  };

  // Tạo danh sách kho duy nhất cho bộ lọc
  const khoList = React.useMemo(
    () => [
      { label: "BC2", value: "BC2" },
      { label: "BC3", value: "BC3" },
      { label: "TG1", value: "TG1" },
      { label: "DT1", value: "DT1" },
      { label: "VL1", value: "VL1" },
      { label: "ST1", value: "ST1" },
      { label: "BL1", value: "BL1" },
      { label: "Nhà máy VTC", value: "VTC" },
    ],
    []
  );

  const loaiList = React.useMemo(
    () => [
      { label: "Loại 001", value: "LOAI001" },
      { label: "Loại 002", value: "LOAI002" },
      { label: "Loại 003", value: "LOAI003" },
    ],
    []
  );

  // Chuyển đổi trạng thái của checkbox "Select All"
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setModalFilterKho(khoList.map((_) => _.value)); // Chọn tất cả kho khi select all
    } else {
      setModalFilterKho([]); // Bỏ chọn tất cả khi unselect all
    }
  };

  // Chuyển đổi trạng thái của checkbox "Select All"
  const handleSelectAllChangeLoai = () => {
    setSelectAllLoai(!selectAllLoai);
    if (!selectAllLoai) {
      setModalFilterLoai(loaiList.map((_) => _.value)); // Chọn tất cả loại khi select all
    } else {
      setModalFilterLoai([]); // Bỏ chọn tất cả khi unselect all
    }
  };

  // Cập nhật kho đã chọn khi checkbox riêng biệt thay đổi
  const handleCheckboxChange = (e) => {
    const selectedKho = e.target.value;
    if (e.target.checked) {
      setModalFilterKho((prev) => [...prev, selectedKho]);
    } else {
      setModalFilterKho((prev) => prev.filter((kho) => kho !== selectedKho));
    }
  };

  const handleCheckboxChangeLoai = (e) => {
    const selectedLoai = e.target.value;
    if (e.target.checked) {
      setModalFilterLoai((prev) => [...prev, selectedLoai]);
    } else {
      setModalFilterLoai((prev) =>
        prev.filter((loai) => loai !== selectedLoai)
      );
    }
  };

  useEffect(() => {
    if (modalFilterKho.length === khoList.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [modalFilterKho, khoList, khoList.length, selectAll]);

  useEffect(() => {
    if (modalFilterLoai.length === loaiList.length) {
      setSelectAllLoai(true);
    } else {
      setSelectAllLoai(false);
    }
  }, [modalFilterLoai, loaiList, loaiList.length, selectAllLoai]);

  const handleApplyFilters = () => {
    setFilterKho(modalFilterKho);
    setFilterLoai(modalFilterLoai);
    saveFiltersToCookies(modalFilterKho, modalFilterLoai);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (searchQuery.length === 0) return;
    handleSearch();
  }, [filterKho, filterLoai]);

  const handleCancelFilters = () => {
    setModalFilterKho(filterKho);
    setModalFilterLoai(filterLoai);
    setIsModalOpen(false);
  };

  // Save filters to cookies
  const saveFiltersToCookies = (cookieKho, cookieLoai) => {
    document.cookie = `filterKho=${JSON.stringify(cookieKho)}; path=/`;
    document.cookie = `filterLoai=${JSON.stringify(cookieLoai)}; path=/`;
  };

  // Load filters from cookies
  const loadFiltersFromCookies = () => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const savedFilterKho = getCookie("filterKho");
    const savedFilterLoai = getCookie("filterLoai");

    if (savedFilterKho) {
      setFilterKho(JSON.parse(savedFilterKho));
      setModalFilterKho(JSON.parse(savedFilterKho));
    }
    if (savedFilterLoai) {
      setFilterLoai(JSON.parse(savedFilterLoai));
      setModalFilterLoai(JSON.parse(savedFilterLoai));
    }
  };

  useEffect(() => {
    loadFiltersFromCookies();
  }, []);

  return (
    <div className="app-container">
      <main className="app-main">
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
        >
          <button className="filter-container">
            <FaFilter
              className="filter-icon"
              onClick={() => setIsModalOpen(true)}
            />
          </button>
        </SearchBar>

        {/* Modal Lọc Kho với Overlay */}
        <div className="container-table100">
          <div className="wrap-table100">
            <div className="table100">
              <table>
                <thead>
                  <tr className="table100-head">
                    <th className="column1">Mã vật tư</th>
                    <th className="column2">Mẫu</th>
                    <th className="column3">Lô</th>
                    <th className="column4">Khả dụng</th>
                    <th className="column5">Tồn kho</th>
                    <th className="column6">Kho</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr className="loader loader--style1" title="0">
                      <td className="column1" colSpan="6">
                        <svg
                          version="1.1"
                          id="loader-1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="80px"
                          height="80px"
                          viewBox="0 0 40 40"
                          enableBackground="new 0 0 40 40"
                        >
                          <path
                            opacity="0.2"
                            fill="#000"
                            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                          />
                          <path
                            fill="#000"
                            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                    C22.32,8.481,24.301,9.057,26.013,10.047z"
                          >
                            <animateTransform
                              attributeType="xml"
                              attributeName="transform"
                              type="rotate"
                              from="0 20 20"
                              to="360 20 20"
                              dur="0.9s"
                              repeatCount="indefinite"
                            />
                          </path>
                        </svg>
                      </td>
                    </tr>
                  )}

                  {!isLoading &&
                    tableData.map((row) => (
                      <tr key={row.id}>
                        <td key={1} className={"column1"}>
                          {row.item_code + ""}
                        </td>
                        <td key={2} className={"column2"}>
                          {row.model + ""}
                        </td>
                        <td key={3} className={"column3"}>
                          {row.lot + ""}
                        </td>
                        <td key={4} className={"column4"}>
                          {row.available_stock + ""}
                        </td>
                        <td key={5} className={"column4"}>
                          {row.inventory_stock + ""}
                        </td>
                        <td key={6} className={"column5"}>
                          {row.warehouse + ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>

      {/* Modal với Checkbox */}
      {isModalOpen && (
        <div className="modal-overlay custom-modal-overlay">
          <div className="modal-content custom-modal-content">
            <div>
              <h2 className="custom-modal-header">Chọn Kho</h2>
              <div className="checkbox-group custom-checkbox-group">
                <span className="custom-checkbox-item">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    className="custom-checkbox"
                  />
                  Chọn tất cả
                </span>
              </div>
              <div
                className="checkbox-group custom-checkbox-group"
                style={{ marginTop: "10px" }}
              >
                {khoList.map((kho, index) => (
                  <span key={index} className="custom-checkbox-item">
                    <input
                      type="checkbox"
                      value={kho.value}
                      onChange={handleCheckboxChange}
                      checked={modalFilterKho.includes(kho.value)}
                      className="custom-checkbox"
                    />
                    {kho.label}
                  </span>
                ))}
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h2 className="custom-modal-header">Chọn Loại</h2>
              <div className="checkbox-group custom-checkbox-group">
                <span className="custom-checkbox-item">
                  <input
                    type="checkbox"
                    checked={selectAllLoai}
                    onChange={handleSelectAllChangeLoai}
                    className="custom-checkbox"
                  />
                  Chọn tất cả
                </span>
              </div>
              <div
                className="checkbox-group custom-checkbox-group"
                style={{ marginTop: "20px" }}
              >
                {loaiList.map((loai, index) => (
                  <span key={index} className="custom-checkbox-item">
                    <input
                      type="checkbox"
                      value={loai.value}
                      onChange={handleCheckboxChangeLoai}
                      checked={modalFilterLoai.includes(loai.value)}
                      className="custom-checkbox"
                    />
                    {loai.label}
                  </span>
                ))}
              </div>
            </div>
            <div className="modal-footer custom-modal-btn-group">
              <button
                onClick={() => {
                  handleApplyFilters();
                }}
                className="btn btn-primary custom-btn custom-modal-btn apply-btn"
              >
                Áp dụng
              </button>
              <button
                onClick={() => handleCancelFilters()}
                className="btn btn-secondary custom-btn custom-modal-btn close-btn"
              >
                Đóng
              </button>
            </div>
          </div>
        </div>
      )}

      <footer className="app-footer">
        <p>© 2024 Bản quyền thuộc công ty phần mềm Cam4K.</p>
      </footer>
    </div>
  );
}

export default App;
